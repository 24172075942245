import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReorderDotsVertical } from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/ui/goal-context-menu';
import { IconButton } from 'shared/components/ui/icon-button';
import { SmallScreenGoalCard } from 'shared/components/ui/small-screen-goal-card';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import { columnToCellFcMap } from './column-to-cell-fc-map';
import * as Styled from './goal-data-grid.style';
import { MenuCell } from './menu-cell';
import { Column, ColumnType, RowData } from './types';

type SortableDataRowProps = React.ComponentProps<typeof Styled.DataRow> & {
  id: ID;
};

const SortableDataRow: React.FC<SortableDataRowProps> = ({ id, ...rest }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.DataRow
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

const Container: React.FC<{
  id: ID;
  isFrozen?: boolean;
  onClick?: (id: ID) => void;
  children: React.ReactNode;
}> = ({ id, isFrozen, onClick, children }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (isFrozen || onClick) {
    const _onClick = () => onClick?.(id);
    return (
      <Styled.RowContainer onClick={_onClick}>{children}</Styled.RowContainer>
    );
  }

  if (theme.isMobile) {
    const nav = () => navigate(replaceUrlParams(Paths.GoalDetail, { id }));
    return <Styled.RowContainer onClick={nav}>{children}</Styled.RowContainer>;
  }

  return (
    <Styled.RowLink to={replaceUrlParams(Paths.GoalDetail, { id })}>
      {children}
    </Styled.RowLink>
  );
};

export type DataRowProps = {
  columns: Column[];
  data: RowData;
  onGoal?: (id: ID) => void;
  onEditGoal?: (id: ID) => void;
  onCompleteGoal?: (id: ID) => void;
  onDeleteGoal?: (id: ID) => void;
  onArchiveGoal?: (id: ID) => void;
  isArchived?: boolean;
  onFavoriteGoal?: (id: ID) => void;
  isFavorite?: boolean;
  isSortable?: boolean;
  isFrozen?: boolean;
};

export const DataRow: React.FC<DataRowProps> = ({
  isSortable,
  data,
  columns,
  onGoal,
  onEditGoal,
  onCompleteGoal,
  onDeleteGoal,
  onArchiveGoal,
  isArchived,
  onFavoriteGoal,
  isFavorite,
  isFrozen,
}) => {
  const theme = useTheme();
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({ disabled: isFrozen });
  const DataRow = isSortable ? SortableDataRow : Styled.DataRow;
  const emptyExcludedColumns = useMemo(
    () => columns.filter(({ type }) => type !== ColumnType.Empty),
    [columns],
  );
  const shouldShowEmptyColumn = emptyExcludedColumns.length < columns.length;

  const onEdit = onEditGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onEditGoal(data.id);
        closeMenu();
      }
    : undefined;
  const onComplete = onCompleteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onCompleteGoal(data.id);
        closeMenu();
      }
    : undefined;
  const onArchive = onArchiveGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onArchiveGoal(data.id);
        closeMenu();
      }
    : undefined;
  const onDelete = onDeleteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onDeleteGoal(data.id);
        closeMenu();
      }
    : undefined;

  const onFavorite = onFavoriteGoal
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        onFavoriteGoal(data.id);
        closeMenu();
      }
    : undefined;

  return (
    <>
      <DataRow
        id={data.id}
        onContextMenu={openMenu}
        $selected={!!menuPosition}
        $isArchived={!!isArchived}
      >
        <Container id={data.id} isFrozen={isFrozen} onClick={onGoal}>
          <Styled.SmallScreen $selected={!!menuPosition}>
            <SmallScreenGoalCard
              name={data.name}
              image={data.image}
              iconName={data.iconName}
              color={data.color}
              completion={data.completion}
              deadline={data.deadline}
              isCompleted={!!data.completedAt}
              isFrozen={isFrozen}
              isArchived={isArchived}
            />
          </Styled.SmallScreen>

          <Styled.OtherScreen $selected={!!menuPosition}>
            {!!isSortable && (
              <Styled.DragHandle>
                <IconButton icon={ReorderDotsVertical} />
              </Styled.DragHandle>
            )}
            {emptyExcludedColumns.map(({ type }) => {
              const DataCell = columnToCellFcMap[type];

              return (
                <Styled.DataRowItem key={type} $type={type}>
                  <DataCell data={data} isFrozen={isFrozen} />
                </Styled.DataRowItem>
              );
            })}

            {shouldShowEmptyColumn && (
              <Styled.DataRowItem $type={ColumnType.Empty}>
                {(onEditGoal ||
                  onCompleteGoal ||
                  onDeleteGoal ||
                  onArchiveGoal ||
                  isFrozen) && (
                  <MenuCell
                    id={data.id}
                    onEditGoal={onEditGoal}
                    onCompleteGoal={onCompleteGoal}
                    onDeleteGoal={onDeleteGoal}
                    onArchiveGoal={onArchiveGoal}
                    onFavoriteGoal={onFavoriteGoal}
                    isFavorite={isFavorite}
                    isFrozen={isFrozen}
                    completedAt={data.completedAt}
                    archivedAt={data.archivedAt}
                  />
                )}
              </Styled.DataRowItem>
            )}
          </Styled.OtherScreen>
        </Container>
      </DataRow>
      {!!menuPosition && !theme.isMobile && (
        <GoalContextMenu
          onClose={closeMenu}
          location={menuPosition}
          position="bottom-start"
          onEditGoal={onEdit}
          isCompleted={!!data.completedAt}
          onCompleteGoal={onComplete}
          isArchived={!!data.archivedAt}
          onArchiveGoal={onArchive}
          onDeleteGoal={onDelete}
          onFavorite={onFavorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};
