import { DndContext } from '@dnd-kit/core';
import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useGoalTodayReorder } from 'features/user';
import React, { useMemo } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { Headers, TaskSection } from 'shared/components/ui/task-section';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { goalInboxID } from 'shared/types/orderable-section';
import { DueDisplayOptions } from 'shared/types/task-base';

import { useGoalTaskSections } from '../../hooks/use-goal-task-sections';
import * as Styled from './goal-grouping-tasks-list.style';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const GoalGroupingTasksList: React.FC = () => {
  const today = useToday();
  const { taskCardTasks, sections: initialSections } = useGoalTaskSections();
  const onDragEnd = useGoalTodayReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const activeItem = useMemo(
    () =>
      activeId ? taskCardTasks.find(({ id }) => id === activeId) : undefined,
    [activeId, taskCardTasks],
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <Styled.Container>
        {sections.map(({ id, items }) => (
          <Styled.Section key={id}>
            <TaskSection
              droppableId={id}
              goals={goals}
              lifeAreas={lifeAreas}
              today={today}
              tasks={items}
              selectedTask={selectedTask}
              date={today}
              goalId={id !== goalInboxID ? id : undefined}
              displayDue={displayDue}
              headerToDisplay={Headers.Goal}
              taskIsMoving={!!activeItem}
              hideGoal
              headerIsLink
              canCollapse
              canCreateTask
              canReorder
            />
          </Styled.Section>
        ))}
      </Styled.Container>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskCard
              task={activeItem.raw.task}
              displayDue={displayDue}
              isSelected={selectedTask === activeItem.id}
              hideGoal
              noHover
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
