import styled, { css } from 'styled-components';

export const List = styled.ul<{
  $preventInteraction: boolean;
  $hasMobileScroll: boolean;
  $isMobileOutlined: boolean;
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  min-width: 0;
  padding: 0;
  margin: 0;

  list-style: none;

  ${({ $preventInteraction }) =>
    $preventInteraction &&
    css`
      pointer-events: none;
    `};

  ${({ theme, $hasMobileScroll }) =>
    theme.isMobile &&
    $hasMobileScroll &&
    css`
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: ${({ theme }) => theme.spacing(1)};
    `};

  ${({ theme, $isMobileOutlined }) =>
    theme.isMobile &&
    $isMobileOutlined &&
    css`
      gap: ${({ theme }) => theme.spacing(2)};
    `};
`;

export const ListItem = styled.li<{
  $hide: boolean;
  $hasValue: boolean;
  $isMobileOutlined: boolean;
  $hasMobileScroll: boolean;
}>`
  max-width: ${({ $hide, $hasValue }) =>
    $hide ? 0 : $hasValue ? '100%' : '2.4rem'};
  min-width: ${({ theme, $isMobileOutlined }) =>
    theme.isMobile && $isMobileOutlined ? '3.8rem' : '2.4rem'};

  transition: max-width 200ms;
  transition-delay: ${({ $hide }) => ($hide ? '0ms' : '100ms')};
  overflow: hidden;

  line-height: 62.5%;

  ${({ theme, $hasMobileScroll }) =>
    theme.isMobile &&
    $hasMobileScroll &&
    css`
      max-width: unset;
      min-width: unset;
      overflow: unset;
    `};
`;
