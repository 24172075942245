import { Components, Theme } from '@mui/material';
import { SimpleInterpolation } from '@mui/styled-engine-sc';

export enum DialogVariantOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const mobilePaper: (theme: Omit<Theme, 'components'>) => SimpleInterpolation = (
  theme,
) => ({
  margin: `${theme.spacing(12)} 0 0`,
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  maxHeight: `calc(100% - ${theme.spacing(12)})`,

  borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} 0 0`,
  boxShadow: theme.shadowBox.dialog,
  background: theme.palette.background.default,
});

const desktopPaper: (
  theme: Omit<Theme, 'components'>,
) => SimpleInterpolation = (theme) => ({
  margin: theme.spacing(4),
  width: `calc(100% - (${theme.spacing(4)} * 2))`,
  maxWidth: '46.4rem',

  borderRadius: theme.spacing(2),
  boxShadow: theme.shadowBox.dialog,
  background: theme.palette.background.default,
});

export const MuiDialog: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) =>
      theme.isMobile ? mobilePaper(theme) : desktopPaper(theme),
  },
  variants: [
    {
      props: { variant: DialogVariantOptions.Medium },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: '64rem',
        },
      },
    },
    {
      props: { variant: DialogVariantOptions.Large },
      style: {
        '& .MuiDialog-paper': {
          maxWidth: '90rem',
        },
      },
    },
  ],
};

declare module '@mui/material/Dialog' {
  interface DialogProps {
    variant?: DialogVariantOptions;
  }
}
