import { useCustomLifeAreas } from 'features/life-areas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenCreateLifeAreaDialog } from 'shared/contexts/life-area-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { PremiumFeature } from 'shared/types/premium-feature';
import { PageHeader } from 'src/shared/components/connected/page-header';
import { useTheme } from 'styled-components';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateLifeAreaDialog = useOpenCreateLifeAreaDialog();

  const lifeAreas = useCustomLifeAreas();
  const createLifeAreaAllowed = usePremiumFeatureAllowed(
    PremiumFeature.UnlimitedLifeAreas,
    { totalAmountOfCustomLifeAreas: lifeAreas.length },
  );
  const createLifeArea = () =>
    createLifeAreaAllowed ? openCreateLifeAreaDialog() : openPremiumDialog();

  return (
    <PageHeader
      title={t('pages.life-areas.title')}
      interactions={
        theme.isMobile ? undefined : (
          <Button
            onClick={createLifeArea}
            size={Sizes.Medium}
            variant={Variants.Outlined}
            color={Colors.Inherit}
            startIcon={Plus}
          >
            {t('pages.life-areas.header.add-life-area')}
          </Button>
        )
      }
    />
  );
};
