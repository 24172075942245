import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/components/connected/page-header';

export const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('pages.goal-templates.title')}
      description={t('pages.goal-templates.description')}
    />
  );
};
