import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Check,
  ChevronLeft,
  Flag,
  IconFormIcons,
  IconFormNames,
  Image as ImageIcon,
  Lock,
  MoreHorizontal,
} from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { GoalContextMenu } from 'shared/components/ui/goal-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { TimeLeft } from 'shared/components/ui/time-left';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { GoalMetric } from 'shared/types/goal-metric';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { countMetricOptions } from 'shared/types/metric-template';
import { Timestamp } from 'shared/types/timestamp';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import * as Styled from './section-goal-header.style';

export type SectionGoalHeaderProps = {
  id: ID;
  goals: Goal[];
  image?: Image | null;
  iconName?: IconFormNames | null;
  imagePlaceholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  name: string;
  progress?: number;
  deadline: Timestamp | null;
  parentIds?: ID[];
  metric?: GoalMetric | null;
  onOpen?: () => void;
  isOpen?: boolean;
  isMainGoal?: boolean;
  onEditGoal?: (id: ID) => void;
  onUpdateGoal?: (goal: Partial<Goal>) => void;
  onUpdateGoalProgress: (id: ID) => void;
  onCompleteGoal?: (id: ID) => void;
  onArchiveGoal?: (id: ID) => void;
  onFavoriteGoal?: (id: ID) => void;
  onDeleteGoal?: (id: ID) => void;
  onPremium: () => void;
  isCompleted?: boolean;
  isArchived?: boolean;
  isFavorite?: boolean;
  isDragging?: boolean;
  isMoving?: boolean;
  isHovered?: boolean;
  isFrozen?: boolean;
};

export const SectionGoalHeader: React.FC<SectionGoalHeaderProps> = ({
  id,
  goals,
  image,
  iconName,
  imagePlaceholderIcon = ImageIcon,
  color,
  name,
  progress,
  deadline,
  metric,
  parentIds,
  onOpen,
  isOpen,
  isMainGoal,
  onEditGoal,
  onUpdateGoal,
  onUpdateGoalProgress,
  onCompleteGoal,
  onArchiveGoal,
  onDeleteGoal,
  onFavoriteGoal,
  onPremium,
  isArchived,
  isFavorite,
  isCompleted,
  isDragging,
  isMoving,
  isHovered,
  isFrozen,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [menuPosition, setMenuPosition] = useState<
    { left: number; top: number } | undefined
  >(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onUnfold = () => {
    if (isFrozen) {
      onPremium();
      return;
    }
    onOpen?.();
  };

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuPosition({ left: e.clientX, top: e.clientY });
    openMenu();
  };

  const onMoreButton = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuPosition(undefined);
    openMenu();
  };

  const onNavigate = theme.isMobile
    ? () => navigate(replaceUrlParams(Paths.GoalDetail, { id }))
    : undefined;

  const onCloseMenu = () => {
    setMenuPosition(undefined);
    closeMenu();
  };

  const onEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    onEditGoal?.(id);
    onCloseMenu();
  };

  const onComplete = (e: React.MouseEvent) => {
    e.preventDefault();
    onCompleteGoal?.(id);
    onCloseMenu();
  };
  const onDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    onDeleteGoal?.(id);
    onCloseMenu();
  };
  const onArchive = (e: React.MouseEvent) => {
    e.preventDefault();
    onArchiveGoal?.(id);
    onCloseMenu();
  };
  const onFavorite = (e: React.MouseEvent) => {
    e.preventDefault();
    onFavoriteGoal?.(id);
    onCloseMenu();
  };

  const onRemoveParentIds = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdateGoal?.({ id, parentIds: null });
    onCloseMenu();
  };

  const onChangeMainGoal = (parentIds: ID[]) => {
    onUpdateGoal?.({ id, parentIds });
    onCloseMenu();
  };

  const onUpdateProgress = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdateGoalProgress?.(id);
    onCloseMenu();
  };

  return (
    <>
      <Styled.Container
        onContextMenu={onContextMenu}
        $isDragging={!!isDragging}
        $isMoving={!!isMoving}
        data-testid={id}
        $isArchived={!!isArchived}
      >
        <Styled.UnfoldButton onClick={onUnfold}>
          <Styled.UnfoldIconContainer $open={!!isOpen}>
            <Icon icon={ChevronLeft} />
          </Styled.UnfoldIconContainer>
        </Styled.UnfoldButton>

        <Styled.Body
          $isDragging={!!isDragging}
          $isHovered={!!isHovered}
          $isOpen={!!isOpen}
        >
          <Styled.Content $isCompleted={!!isCompleted}>
            <Styled.IllustrationContainer>
              {image ? (
                <Styled.BlurImageContainer $isCompleted={!!isCompleted}>
                  <BlurHashImage hash={image.blurHash} src={image.url} />
                </Styled.BlurImageContainer>
              ) : (
                <Styled.ImageIconContainer $isCompleted={!!isCompleted}>
                  <Icon
                    icon={
                      iconName ? IconFormIcons[iconName] : imagePlaceholderIcon
                    }
                  />
                </Styled.ImageIconContainer>
              )}
              {isCompleted && (
                <Styled.CompletedIconContainer>
                  <Icon icon={Check} />
                </Styled.CompletedIconContainer>
              )}
              {!!color && <Styled.ColorDot $color={color} />}
            </Styled.IllustrationContainer>
            <Styled.Link
              to={replaceUrlParams(Paths.GoalDetail, { id })}
              onClick={onNavigate}
              as={theme.isMobile || isFrozen ? 'span' : undefined}
              $isFrozen={!!isFrozen}
              $isMain={!!isMainGoal}
            >
              <Styled.LinkLabel>{name}</Styled.LinkLabel>
              <Styled.LinkIconContainer $isFaded={!!isFrozen}>
                <Icon icon={isFrozen ? Lock : ChevronLeft} />
              </Styled.LinkIconContainer>
            </Styled.Link>
          </Styled.Content>

          <Styled.MetaContainer>
            <Styled.MetaEntry $isFrozen={!!isFrozen} $type="progress">
              <Styled.ProgressButton
                onClick={
                  !metric?.id ||
                  countMetricOptions.includes(metric?.id) ||
                  isFrozen ||
                  isArchived
                    ? undefined
                    : onUpdateProgress
                }
                as={
                  !metric?.id ||
                  countMetricOptions.includes(metric?.id) ||
                  isFrozen ||
                  isArchived
                    ? 'span'
                    : undefined
                }
              >
                <CircleProgress
                  percentage={progress ?? 0}
                  isPrimary={!isFrozen}
                />
              </Styled.ProgressButton>
            </Styled.MetaEntry>
            <Styled.MetaEntry $isFrozen={!!isFrozen} $type="deadline">
              {!!deadline && !isCompleted && (
                <TimeLeft startIcon={Flag} date={deadline} />
              )}
            </Styled.MetaEntry>
          </Styled.MetaContainer>

          <Styled.IconButtonContainer>
            <IconButton
              ref={buttonRef}
              icon={MoreHorizontal}
              onClick={onMoreButton}
              size={Sizes.Medium}
            />
          </Styled.IconButtonContainer>
        </Styled.Body>
      </Styled.Container>

      {menuOpen && !theme.isMobile && (
        <GoalContextMenu
          referenceElement={menuPosition ? undefined : buttonRef}
          location={menuPosition}
          onClose={closeMenu}
          onEditGoal={isFrozen ? undefined : onEdit}
          isCompleted={!!isCompleted}
          onCompleteGoal={isFrozen ? undefined : onComplete}
          isArchived={!!isArchived}
          onArchiveGoal={onArchive}
          onDeleteGoal={onDelete}
          onFavorite={onFavorite}
          isFavorite={isFavorite}
          onMoveGoal={onChangeMainGoal}
          onGoalToMain={onRemoveParentIds}
          goals={goals}
          parentIds={parentIds}
        />
      )}
    </>
  );
};
