import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { TodayProvider } from 'shared/contexts/today';
import { queryClient } from 'shared/lib/@tanstack-query';
import { useLocale } from 'shared/lib/date-fns';
import { ThemeProvider } from 'shared/lib/mui';
import { Routes } from 'shared/routes';

export const App: React.FC = () => {
  // set date-fns locale
  useLocale();

  return (
    <ThemeProvider>
      <TodayProvider>
        <QueryClientProvider client={queryClient}>
          {/*{process.env.NODE_ENV === 'development' && (*/}
          {/*  <ReactQueryDevtools initialIsOpen={false} />*/}
          {/*)}*/}
          <Routes />
        </QueryClientProvider>
      </TodayProvider>
    </ThemeProvider>
  );
};
