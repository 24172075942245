import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(1)} 0 0`};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  width: 100%;
`;

export const TitleContainer = styled.div`
  flex: 1;
`;

export const CheckboxContainer = styled.div`
  line-height: 62.5%;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};

  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  overflow: auto;
`;

export const DescriptionContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing(8)};
`;

export const MetaInteractionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow: auto;
  margin-top: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  max-width: 100%;

  padding: ${({ theme }) => theme.spacing(3)};
`;

export const LinkedItemContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

export const SubmitButton = styled.button`
  display: block;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};

  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};

  color: ${({ theme }) => theme.palette.common.white};
  font-size: 2rem;
  line-height: 62.5%;

  &:disabled {
    opacity: 0.4;
  }
`;
