import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $minWidth: string;
  $maxWidth: string;
  $fullWidth: boolean;
}>`
  position: relative;
  display: inline-block;
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};

  line-height: ${({ theme }) => theme.typography.buttonSmall.lineHeight};

  &:focus-within {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      min-width: initial;
      max-width: initial;
      width: 100%;
    `};
`;

export const IconContainer = styled.span<{ $hasValue: boolean }>`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%);
  transition: color 200ms;

  font-size: 1.2rem;
  line-height: 62.5%;
  color: ${({ $hasValue, theme }) =>
    $hasValue ? 'inherit' : theme.palette.text.secondary};
`;

const ContentBase = css<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
}>`
  display: block;
  box-sizing: border-box;
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  padding: ${({ $hasIconLeft, $hasIconRight, theme }) =>
    `${theme.spacing(0.5)} ${theme.spacing($hasIconRight ? 6.5 : 2)}
    ${theme.spacing(0.5)} ${theme.spacing($hasIconLeft ? 6.5 : 2)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme, $showAsFormInput }) =>
    $showAsFormInput &&
    css`
      ${typographyToCss(theme.typography.body2)};

      padding: ${theme.isMobile
        ? `${theme.spacing(2.5)} ${theme.spacing(3)}`
        : `${theme.spacing(1.5)} ${theme.spacing(2)}`};
    `};
`;

export const Input = styled.input<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
}>`
  ${ContentBase};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  background: transparent;
  transition:
    border-color 200ms,
    color 200ms;

  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SizingSpan = styled.span<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
}>`
  ${ContentBase};
  visibility: hidden;
  white-space: pre;
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(2)};
  margin: 0;
  padding: 0;

  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.2rem;
  line-height: 62.5%;
`;
