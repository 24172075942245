import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  container-type: inline-size;
  display: flex;
  align-items: center;

  padding: ${({ theme }) =>
    theme.isMobile ? `0 ${theme.spacing(3)} 0 0` : `0 ${theme.spacing(3)}`};
  height: 3.2rem;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DetailSection = styled.span`
  display: block;
  min-width: 13.6rem;

  ${({ theme }) =>
    `@container (min-width: ${theme.breakpoints.values.xs + 1}px)`} {
    width: 25.4rem;
    max-width: 40%;
  }
`;

export const ResizeContainer = styled.div`
  flex: 1;
  position: relative;
`;

export const ButtonContainer = styled.div<{ $isRight?: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(-100%, -50%);
  height: 100%;

  color: ${({ theme }) => theme.palette.text.primary};

  ${({ $isRight }) =>
    $isRight &&
    css`
      right: 0;
      left: initial;

      transform: translateY(-50%);
    `};
`;

export const WeeksContainer = styled.div<{
  $visibleDates: number;
  $dateWidth: number;
}>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  height: 100%;
  width: ${({ $visibleDates, $dateWidth }) => $visibleDates * $dateWidth}px;
  margin-left: auto;

  overflow: hidden;
`;

export const Week = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
`;

export const WeekEntry = styled.span<{ $width: number; $highlight?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: ${({ $width }) => $width}px;
  
  background: ${({ theme }) => (theme.isMobile ? theme.palette.background.paper : theme.palette.background.default)};
  
  ${({ $highlight, theme }) =>
    $highlight &&
    css`
      color: ${theme.palette.text.primary};
      font-weight: ${theme.typography.fontWeightBold};
    `}}
`;

export const Divider = styled.span`
  display: inline-block;
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.divider};
`;
