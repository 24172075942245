import { parse } from 'date-fns';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Bell, BellDelete } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuInput,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { reminderTimes } from 'shared/constants';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { isValidTime } from 'shared/utils/is-valid-time';
import { parseReminderTime } from 'shared/utils/parse-reminder-time';
import { processStringToHoursAndMinutes } from 'shared/utils/process-string-to-hours-and-minutes';

import * as Styled from './task-meta-interaction-reminder-time.style';

export type TaskMetaInteractionReminderTimeProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  value?: number | null;
  onChange: (value?: number) => void;
};

export const TaskMetaInteractionReminderTime: React.FC<
  TaskMetaInteractionReminderTimeProps
> = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>(
    value ? parseReminderTime(value) : '',
  );
  const [inputError, setInputError] = useState(false);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const parsedValue = useMemo(
    () => (value ? parseReminderTime(value) : undefined),
    [value],
  );

  const onClear = () => {
    onChange();
    closeMenu();
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    if (!target.value.length) {
      setInputValue('');
      return;
    }

    // Process textual input to the format we expect
    const hoursAndMinutes = processStringToHoursAndMinutes(target.value);

    // Update the input
    setInputValue(hoursAndMinutes);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [menuOpen]);

  useKeysDown(['Enter'], () => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      const inputVal = inputRef.current.value;

      if (!inputVal || isValidTime(inputValue)) {
        const date = inputVal
          ? parse(inputVal, 'HH:mm', new Date())
          : undefined;

        onChange(
          date
            ? (date.getHours() * 60 + date.getMinutes()) * 60 * 1000
            : undefined,
        );
        closeMenu();
        return;
      }

      setInputError(true);
    }
  });

  useClickOutside(containerRef, closeMenu);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        start={<Icon icon={Bell} />}
        onClick={openMenu}
        label={parsedValue}
        tooltipLabel={t(
          parsedValue
            ? 'task.reminder.tooltip.edit.label'
            : 'task.reminder.tooltip.add.label',
        )}
        selected={menuOpen}
        {...rest}
      />

      {menuOpen && (
        <PopupMenu referenceElement={containerRef}>
          <PopupMenuList hasBottomBorder>
            <PopupMenuListItem>
              <PopupMenuInput
                ref={inputRef}
                iconColor={inputError ? Colors.Error : undefined}
                startIcon={Bell}
                textColor={inputError ? Colors.Error : undefined}
                value={inputValue}
                placeholder={reminderTimes[0]}
                onChange={handleInputChange}
              />
            </PopupMenuListItem>
          </PopupMenuList>

          <PopupMenuList hasBottomBorder={!!value} isScrollable>
            {reminderTimes.map((option) => {
              const onItem = () => {
                const date = parse(option, 'HH:mm', new Date());
                onChange(
                  (date.getHours() * 60 + date.getMinutes()) * 60 * 1000,
                );
                closeMenu();
              };

              return (
                <PopupMenuListItem key={option}>
                  <PopupMenuButton
                    start={<Icon icon={Bell} />}
                    onClick={onItem}
                    tabIndex={0}
                    type="button"
                  >
                    {option}
                  </PopupMenuButton>
                </PopupMenuListItem>
              );
            })}
          </PopupMenuList>
          <PopupMenuList>
            {!!value && (
              <PopupMenuListItem>
                <PopupMenuButton
                  start={<Icon icon={BellDelete} />}
                  startColor={Colors.Error}
                  textColor={Colors.Error}
                  onClick={onClear}
                  tabIndex={0}
                >
                  {t('task.reminder.options.no-reminder.label')}
                </PopupMenuButton>
              </PopupMenuListItem>
            )}
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
