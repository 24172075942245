import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'shared/components/ui/checkbox';
import { ResizableTextArea } from 'shared/components/ui/resizable-text-area';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useKeysDown } from 'shared/hooks/use-keys-down';

import * as Styled from './sub-task-card.style';

export type NewSubTaskCardProps = {
  onSubmit: (name: string) => void;
};

export const NewSubTaskCard: React.FC<NewSubTaskCardProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const nameInputRef = useRef<HTMLTextAreaElement>(null);
  const [updatedName, setUpdatedName] = useState('');

  const _onClick = () => nameInputRef.current?.focus();

  const submit = () => {
    updatedName && onSubmit(updatedName);
    setUpdatedName('');
  };

  useKeysDown(['Enter'], (e) => {
    if (document.activeElement === nameInputRef.current) {
      e.preventDefault();

      submit();
      _onClick();
      return;
    }
  });

  useClickOutside(containerRef, submit);

  return (
    <Styled.Container ref={containerRef} onClick={_onClick}>
      <Styled.CheckboxContainer>
        <Checkbox checked={false} readOnly isPlaceholder />
      </Styled.CheckboxContainer>

      <Styled.TitleContainer>
        <ResizableTextArea
          ref={nameInputRef}
          value={updatedName}
          onChange={setUpdatedName}
          placeholder={t('forms.new-task.sub-task.title.placeholder')}
        />
      </Styled.TitleContainer>
    </Styled.Container>
  );
};
