import { useCreateUnlimitedGoalsAllowed } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { PageHeader } from 'src/shared/components/connected/page-header';
import { useTheme } from 'styled-components';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateGoalDialog = useOpenCreateGoalDialog();

  const createGoalAllowed = useCreateUnlimitedGoalsAllowed();

  const createGoal = () =>
    createGoalAllowed ? openCreateGoalDialog() : openPremiumDialog();

  return (
    <PageHeader
      title={t('pages.goals.title')}
      interactions={
        theme.isMobile ? undefined : (
          <Button
            onClick={createGoal}
            size={Sizes.Medium}
            variant={Variants.Outlined}
            color={Colors.Inherit}
            startIcon={Plus}
          >
            {t('pages.goals.header.add-goal')}
          </Button>
        )
      }
    />
  );
};
