import React, { Suspense, useEffect, useRef } from 'react';
import { TaskDetailColumn } from 'shared/components/connected/task-detail-column';
import { TaskMoreButton } from 'shared/components/connected/task-more-button';
import { DetailColumnContainer } from 'shared/components/ui/detail-column-container';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { DataType } from 'shared/types/data-type';
import { ID } from 'shared/types/id';
import { elementHasAttribute } from 'shared/utils/element-has-attribute';

export type DesktopDetailColumnProps = {
  taskId?: ID;
  onClose: () => void;
};

export const DesktopDetailColumn: React.FC<DesktopDetailColumnProps> = ({
  taskId,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const closeTimerRef = useRef<number>();

  useEffect(() => {
    closeTimerRef.current && window.clearTimeout(closeTimerRef.current);
  }, [taskId]);

  useClickOutside(containerRef, (event) => {
    if (
      !!event.target &&
      elementHasAttribute(
        event.target as Element,
        'data-component-type',
        DataType.Task,
      )
    ) {
      return;
    }

    closeTimerRef.current = window.setTimeout(onClose, 200);
  });

  return (
    <DetailColumnContainer
      ref={containerRef}
      open={!!taskId}
      onClose={onClose}
      actions={[<TaskMoreButton taskId={taskId} />]}
    >
      {!!taskId && (
        <Suspense>
          <TaskDetailColumn taskId={taskId} onClose={onClose} />
        </Suspense>
      )}
    </DetailColumnContainer>
  );
};
