import {
  Data,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import {
  handleItemBetweenNestedSectionMovement,
  handleItemWithinNestedSectionMovement,
} from 'shared/utils/handle-nested-sections-movement';

type Options = {
  items?: (Goal | TaskCardTask)[];
  onDragEnd?: (changedItems: (Goal | TaskCardTask)[]) => void;
};

export const useSortableNestedSectionsControls = ({
  items,
  onDragEnd,
}: Options) => {
  const changesRef = useRef<Map<ID, Goal | TaskCardTask>>(new Map());
  const [sortedItems, setSortedItems] = useState<(Goal | TaskCardTask)[]>(
    items ?? [],
  );
  const [activeId, setActiveId] = useState<ID>();
  const [activeData, setActiveData] = useState<Data>();
  const activeItem = useMemo(
    () =>
      activeId ? sortedItems.find(({ id }) => id === activeId) : undefined,
    [activeId, sortedItems],
  );

  useEffect(() => {
    if (items) {
      setSortedItems(items);
    }
  }, [items]);

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setActiveId(active.id.toString());
    // Keep track of the active data, as a task can be dragged into a closed sub-goal,
    // which means the task is not rendered anymore. This results in an empty data.current
    setActiveData(active.data.current);
  }, []);

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over?.id || over.id === active.id) {
        return;
      }
      setSortedItems((oldItems) => {
        const changes = handleItemBetweenNestedSectionMovement(
          oldItems,
          // todo: find a better solution for this
          { ...active, data: { current: activeData } },
          over,
        );
        changes.changedItems.forEach((item) =>
          changesRef.current.set(item.id, item),
        );
        return changes.items;
      });
    },
    [activeData],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveId(undefined);
      if (!over?.id || !activeId) {
        return;
      }

      const changes = handleItemWithinNestedSectionMovement(
        sortedItems,
        active,
        over,
      );
      changes.changedItems.forEach((item) =>
        changesRef.current.set(item.id, item),
      );
      const changedItems = Array.from(changesRef.current.values());
      changesRef.current.clear();

      setSortedItems(changes.items);

      return onDragEnd?.(changedItems);
    },
    [activeId, onDragEnd, sortedItems],
  );

  const handleDragCancel = useCallback(() => setActiveId(undefined), []);

  return {
    items: sortedItems,
    activeItem,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
  };
};
