import React, { forwardRef } from 'react';

import * as Styled from './resizable-text-area.style';
import { Variants } from './types';

export type ResizableTextAreaProps = {
  value: string;
  onChange: (value: string) => void;
  variant?: Variants;
  onClick?: () => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  placeholder?: string;
  disabled?: boolean;
  isStrikeThrough?: boolean;
  fullWidth?: boolean;
};

export const ResizableTextArea = forwardRef<
  HTMLTextAreaElement,
  ResizableTextAreaProps
>(
  (
    {
      value,
      onChange,
      variant = Variants.Body2,
      onClick,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      isStrikeThrough,
      fullWidth,
    },
    ref,
  ) => {
    const _onChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange(target.value);

    return (
      <Styled.Container $fullWidth={!!fullWidth}>
        <Styled.SizingSpan
          $isStrikeThrough={!!isStrikeThrough}
          $variant={variant}
        >
          {value || placeholder}
        </Styled.SizingSpan>
        <Styled.NameInput
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={_onChange}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          $isStrikeThrough={!!isStrikeThrough}
          $variant={variant}
          $enabled={!disabled}
        ></Styled.NameInput>
      </Styled.Container>
    );
  },
);
