import React, { forwardRef, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import * as Styled from './resizable-container.style';

interface ResizableContainerProps {
  open: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

export const ResizableContainer = forwardRef<
  HTMLDivElement,
  ResizableContainerProps
>(({ open, contentRef, children }, ref) => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(Styled.initialContainerWidth);

  const handleResizeStart = () => setIsResizing(true);

  const handleResizeMove = (
    event: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => setWidth(size.width);

  const handleResizeEnd = () => setIsResizing(false);

  return (
    <>
      <Styled.MobileBackdrop $open={open} />
      <Resizable
        width={width}
        axis="x"
        onResizeStart={handleResizeStart}
        onResize={handleResizeMove}
        onResizeStop={handleResizeEnd}
        resizeHandles={['w']}
        handle={(_, ref) => (
          <Styled.ResizeHandle
            $open={open}
            $isResizing={isResizing}
            ref={ref}
          />
        )}
        minConstraints={[Styled.minContainerWidth, 0]}
        maxConstraints={[Styled.maxContainerWidth, 0]}
      >
        <Styled.ResizableContainer
          ref={ref}
          $open={open}
          $width={width}
          $isResizing={isResizing}
        >
          <Styled.Content ref={contentRef}>{children}</Styled.Content>
        </Styled.ResizableContainer>
      </Resizable>
    </>
  );
});
