import React, { useRef, useState } from 'react';
import {
  ResizableTextArea,
  Variants,
} from 'shared/components/ui/resizable-text-area';
import { useKeysDown } from 'shared/hooks/use-keys-down';

export type ResizableTitleProps = {
  value: string;
  completed?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const ResizableTitle: React.FC<ResizableTitleProps> = ({
  value,
  completed,
  placeholder,
  onChange,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [currentValue, setCurrentValue] = useState(value);

  const submitChange = () => {
    if (!value || currentValue === value) {
      return;
    }

    onChange(currentValue);
  };

  useKeysDown(['Enter'], (e) => {
    if (document.activeElement === inputRef.current) {
      e.preventDefault();
      inputRef.current?.blur();
    }
  });

  return (
    <ResizableTextArea
      ref={inputRef}
      value={currentValue}
      placeholder={placeholder}
      onChange={setCurrentValue}
      variant={Variants.H5}
      onBlur={submitChange}
      isStrikeThrough={completed}
    />
  );
};
