import {
  BookOpen,
  Calendar,
  CalendarUpcoming,
  CheckCircle,
  Heart,
  Inbox,
  Repeat,
  Target,
} from 'shared/assets/icons';
import { Paths } from 'shared/routes';

import {
  MainNavigationItemId,
  MobileNavigationItem,
  NavigationItem,
} from './types/navigation-item';

export const mainNavigation: NavigationItem[] = [
  {
    id: MainNavigationItemId.Todo,
    translationLabel: 'general.menu.navigation.todo',
    subItems: [
      {
        id: MainNavigationItemId.Inbox,
        translationLabel: 'general.menu.navigation.todo.inbox',
        link: Paths.Inbox,
        pathsToMatch: [Paths.Inbox],
        icon: Inbox,
      },
      {
        id: MainNavigationItemId.Today,
        translationLabel: 'general.menu.navigation.todo.today',
        link: Paths.Home,
        pathsToMatch: [Paths.Home],
        icon: Calendar,
      },
      {
        id: MainNavigationItemId.Upcoming,
        translationLabel: 'general.menu.navigation.todo.upcoming',
        link: Paths.Upcoming,
        pathsToMatch: [Paths.Upcoming],
        icon: CalendarUpcoming,
      },
      // {
      //   id: MainNavigationItemId.AllTasks,
      //   translationLabel: 'general.menu.navigation.todo.all-tasks',
      //   link: Paths.AllTasks,
      //   pathsToMatch: [Paths.AllTasks],
      //   icon: Layers,
      // },
    ],
  },
  {
    id: MainNavigationItemId.MyLife,
    translationLabel: 'general.menu.navigation.my-life',
    subItems: [
      {
        id: MainNavigationItemId.LifeAreas,
        translationLabel: 'general.menu.navigation.my-life.life-areas',
        link: Paths.LifeAreas,
        pathsToMatch: [Paths.LifeAreas],
        icon: Heart,
      },
      {
        id: MainNavigationItemId.Goals,
        translationLabel: 'general.menu.navigation.my-life.goals',
        link: Paths.Goals,
        pathsToMatch: [
          Paths.Goals,
          Paths.GoalsCompleted,
          Paths.GoalsArchived,
          Paths.GoalsThisYear,
          Paths.GoalsNextYear,
          Paths.GoalsAll,
        ],
        icon: Target,
      },
      {
        id: MainNavigationItemId.Habits,
        translationLabel: 'general.menu.navigation.my-life.habits',
        link: Paths.Habits,
        pathsToMatch: [Paths.Habits],
        icon: Repeat,
      },
      {
        id: MainNavigationItemId.Journal,
        translationLabel: 'general.menu.navigation.my-life.journal',
        pathsToMatch: [Paths.Journal],
        icon: BookOpen,
        comingSoon: true,
      },
    ],
  },
];

export const mainMobileNavigation: MobileNavigationItem[] = [
  {
    id: MainNavigationItemId.Todo,
    translationLabel: 'general.menu.navigation.todo',
    link: Paths.Home,
    pathsToMatch: [Paths.Home, Paths.Inbox, Paths.Upcoming],
    icon: CheckCircle,
  },
  {
    id: MainNavigationItemId.Habits,
    translationLabel: 'general.menu.navigation.my-life.habits',
    link: Paths.Habits,
    pathsToMatch: [Paths.Habits],
    icon: Repeat,
  },
  {
    id: MainNavigationItemId.Goals,
    translationLabel: 'general.menu.navigation.my-life.goals',
    link: Paths.Goals,
    pathsToMatch: [
      Paths.Goals,
      Paths.GoalsCompleted,
      Paths.GoalsArchived,
      Paths.GoalsThisYear,
      Paths.GoalsNextYear,
      Paths.GoalsAll,
    ],
    icon: Target,
  },
  {
    id: MainNavigationItemId.LifeAreas,
    translationLabel: 'general.menu.navigation.my-life.life-areas',
    link: Paths.LifeAreas,
    pathsToMatch: [Paths.LifeAreas],
    icon: Heart,
  },
];
