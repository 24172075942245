import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActivePath } from 'shared/hooks/use-active-path';
import { mobileTodoNavigation } from 'shared/routes/navigation';
import { PageHeader } from 'src/shared/components/connected/page-header';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath({ level: 2 });

  return (
    <PageHeader
      title={t('pages.inbox.title')}
      mobileNavigation={mobileTodoNavigation}
      activePath={activePath}
    />
  );
};
