import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { TaskCardTask } from 'shared/types/task-card-task';

type Item = TaskCardTask | Goal;

export const handleItemBetweenNestedSectionMovement = (
  items: Item[],
  active: Active,
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const activeItemIndex = items.findIndex(
    ({ id }) => id === active.id.toString(),
  );
  const overItemIndex = items.findIndex(({ id }) => id === over.id.toString());
  const activeItem = items[activeItemIndex];
  const overItem = items[overItemIndex];

  if (!activeItem || !overItem || activeItemIndex === overItemIndex) {
    return { items, changedItems: [] };
  }

  if (active.data.current?.type === DraggableOptions.Task) {
    const activeTask = activeItem as TaskCardTask;
    const activeParentIndex = items.findIndex(
      ({ id }) => id === activeTask.fields.goalId,
    );
    const activeParent = items[activeParentIndex] as Goal | undefined;

    if (!activeParent) {
      return { items, changedItems: [] };
    }

    if (
      over.data.current?.type === DraggableOptions.Goal &&
      activeParentIndex !== overItemIndex
    ) {
      const overGoal = overItem as Goal;
      const newIndex =
        activeParentIndex < overItemIndex
          ? 0
          : overGoal.taskSorting?.length ?? 0;

      activeParent.taskSorting =
        activeParent.taskSorting?.filter((id) => id !== activeItem.id) ?? null;

      activeTask.fields.goalId = overGoal.id;
      overGoal.taskSorting = (overGoal.taskSorting ?? []).toSpliced(
        newIndex,
        0,
        activeItem.id,
      );
      return {
        items: [...items],
        changedItems: [activeParent, overGoal, activeTask],
      };
    }

    if (over.data.current?.type === DraggableOptions.Task) {
      const overTask = overItem as TaskCardTask;
      const overParentIndex = items.findIndex(
        ({ id }) => id === overTask.fields.goalId,
      );
      const overParent = items[overParentIndex] as Goal | undefined;

      if (!overParent) {
        return { items, changedItems: [] };
      }

      const newIndex =
        activeParentIndex < overParentIndex
          ? 0
          : overParent.taskSorting?.length ?? 0;

      activeParent.taskSorting =
        activeParent.taskSorting?.filter((id) => id !== activeItem.id) ?? null;
      activeTask.fields.goalId = overParent.id;
      overParent.taskSorting = (overParent.taskSorting ?? []).toSpliced(
        newIndex,
        0,
        activeItem.id,
      );

      return {
        items: [...items],
        changedItems: [activeParent, overParent, activeTask],
      };
    }
  }

  if (active.data.current?.type === DraggableOptions.Goal) {
    // when moving a goal over a task, prevent the goal from swapping places with the task
    if (over.data.current?.type === DraggableOptions.Task) {
      return { items: [...items], changedItems: [] };
    }
  }

  return { items, changedItems: [] };
};

export const handleItemWithinNestedSectionMovement = (
  items: Item[],
  active: Active,
  over: Over,
): { items: Item[]; changedItems: Item[] } => {
  const activeItemIndex = items.findIndex(
    ({ id }) => id === active.id.toString(),
  );
  const overItemIndex = items.findIndex(({ id }) => id === over.id.toString());
  const activeItem = items[activeItemIndex];
  const overItem = items[overItemIndex];

  if (!activeItem || !overItem || activeItemIndex === overItemIndex) {
    return { items, changedItems: [] };
  }

  if (active.data.current?.type === DraggableOptions.Task) {
    const activeTask = activeItem as TaskCardTask;
    const activeParentIndex = items.findIndex(
      ({ id }) => id === activeTask.fields.goalId,
    );
    const activeParent = items[activeParentIndex] as Goal | undefined;

    if (!activeParent) {
      return { items, changedItems: [] };
    }

    // if the over is goal, the location of the item has already been set during the moving
    if (over.data.current?.type === DraggableOptions.Goal) {
      return { items: [...items], changedItems: [] };
    }

    if (over.data.current?.type === DraggableOptions.Task) {
      const overTask = overItem as TaskCardTask;
      const taskSorting = activeParent.taskSorting ?? [];
      const overIndex = taskSorting.findIndex((id) => id === overTask.id) ?? 0;
      const activeIndex =
        taskSorting.findIndex((id) => id === activeTask.id) ?? 0;
      activeParent.taskSorting = arrayMove(taskSorting, activeIndex, overIndex);

      return { items: [...items], changedItems: [activeParent] };
    }
  }

  if (active.data.current?.type === DraggableOptions.Goal) {
    // a goal cannot move to the place of a task, so it keeps the place it had.
    if (over.data.current?.type === DraggableOptions.Task) {
      return { items: [...items], changedItems: [] };
    }

    const activeGoal = activeItem as Goal;

    if (!activeGoal.parentIds?.length) {
      return { items, changedItems: [] };
    }

    if (over.data.current?.type === DraggableOptions.Goal) {
      const overGoal = overItem as Goal;

      if (!overGoal.parentIds?.length) {
        return { items, changedItems: [] };
      }

      const activeParentIndex = items.findIndex(
        ({ id }) =>
          id === activeGoal.parentIds![activeGoal.parentIds!.length - 1],
      );
      const activeParent = items[activeParentIndex] as Goal | undefined;

      const overParentIndex = items.findIndex(
        ({ id }) => id === overGoal.parentIds![overGoal.parentIds!.length - 1],
      );
      const overParent = items[overParentIndex] as Goal | undefined;

      if (!activeParent || !overParent) {
        return { items, changedItems: [] };
      }

      const goalSorting = overParent.goalSorting ?? [];

      const activeIndex =
        goalSorting.findIndex((id) => id === activeItem.id) ?? 0;
      const overIndex = goalSorting.findIndex((id) => id === overItem.id) ?? 0;

      overParent.goalSorting = arrayMove(goalSorting, activeIndex, overIndex);

      return { items: [...items], changedItems: [overParent] };
    }
  }

  return { items, changedItems: [] };
};
