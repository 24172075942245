import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'shared/assets/icons';
import { Button, Sizes as ButtonSizes } from 'shared/components/ui/button';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';

import * as Styled from './dialog-header.style';

export type FormDialogHeaderProps = {
  onCancel: () => void;
  title: string;
  canSubmit?: boolean;
};

export const FormDialogHeader: React.FC<FormDialogHeaderProps> = ({
  title,
  onCancel,
  canSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.MobileButtonContainer>
          <IconButton icon={X} size={Sizes.Large} onClick={onCancel} />
        </Styled.MobileButtonContainer>
        <Typography variant="h5" component="h1">
          {title}
        </Typography>
      </Styled.Content>

      <Styled.MobileButtonContainer>
        <Button size={ButtonSizes.Medium} type="submit" disabled={!canSubmit}>
          {t('general.labels.save')}
        </Button>
      </Styled.MobileButtonContainer>
    </Styled.Container>
  );
};
