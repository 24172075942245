import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
  styleOverrides: {
    root: () => ({
      height: 'auto',
      minHeight: 'auto',
    }),
    flexContainer: ({ theme }) => ({
      gap: theme.spacing(2),
    }),
    indicator: {
      display: 'none',
    },
  },
};

export const MuiTab: Components<Omit<Theme, 'components'>>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: `0 ${theme.spacing(3)}`,
      minWidth: 0,
      textTransform: 'initial',
      height: theme.isMobile ? '3.2rem' : '2.8rem',
      minHeight: theme.isMobile ? '3.2rem' : '2.8rem',
      border: `1px solid ${theme.palette.grey['100']}`,
      borderRadius: theme.spacing(theme.isMobile ? 3 : 2),
      transition: 'background 200ms',
      fontSize: theme.typography.buttonSmall.fontSize,
      lineHeight: theme.typography.buttonSmall.lineHeight,

      '&.Mui-selected': {
        background: theme.palette.primaryExtended.opacityBackgroundBackground,
        color: theme.palette.primaryExtended.opacityBackgroundText,
        borderColor: 'transparent',
      },
    }),
    iconWrapper: {
      padding: 0,
    },
    labelIcon: ({ theme }) => ({
      minHeight: theme.isMobile ? '3.2rem' : '2.8rem',
      height: theme.isMobile ? '3.2rem' : '2.8rem',
    }),
  },
  defaultProps: {
    disableRipple: true,
  },
};
