import { useCreateHabitAllowed } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { PageHeader } from 'src/shared/components/connected/page-header';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateHabitDialog = useOpenCreateHabitDialog();
  const createHabitAllowed = useCreateHabitAllowed();

  const createHabit = () =>
    createHabitAllowed ? openCreateHabitDialog() : openPremiumDialog();

  return (
    <PageHeader
      title={t('pages.habits.title')}
      interactions={
        <Button
          onClick={createHabit}
          variant={Variants.Outlined}
          size={Sizes.Medium}
          color={Colors.Inherit}
          startIcon={Plus}
        >
          {t('pages.habits.header.add-habit')}
        </Button>
      }
    />
  );
};
