import { useUpdateGoal } from 'features/goals';
import { useUpdateTask } from 'features/tasks';
import { useCallback, useMemo } from 'react';
import { Goal } from 'shared/types/goal';
import { GoalSortingMode, SortingMode } from 'shared/types/sorting-mode';
import { CheckedTaskState, TaskCardTask } from 'shared/types/task-card-task';
import { mapTaskCardTaskToTask } from 'shared/utils/mappers/map-task-card-task-to-task';

export const useHandleTaskAndGoalChanges = (
  initialItems: (Goal | TaskCardTask)[],
) => {
  const { submit: updateGoal } = useUpdateGoal();
  const { submit: updateTask } = useUpdateTask();
  const oldItems = useMemo(() => structuredClone(initialItems), [initialItems]);

  return useCallback(
    (changes: (Goal | TaskCardTask)[]) => {
      // first get the changed task to update the goal-counts with.
      const changedTask = changes.find((change) => 'fields' in change) as
        | TaskCardTask
        | undefined;
      const initialTask = oldItems.find(
        (change) => change.id === changedTask?.id,
      ) as TaskCardTask | undefined;

      changes.forEach((change) => {
        if ('fields' in change) {
          updateTask(mapTaskCardTaskToTask(change));
          return;
        }

        // if there is a changed task, we need to update the goal statistics as well
        if (
          changedTask &&
          changedTask?.fields.goalId !== initialTask?.fields.goalId &&
          [changedTask?.fields.goalId, initialTask?.fields.goalId].includes(
            change.id,
          )
        ) {
          change.taskCount = change.taskSorting?.includes(changedTask.id)
            ? 1
            : -1;
          change.completedTaskCount = change.taskSorting?.includes(
            changedTask.id,
          )
            ? [
                CheckedTaskState.Checked,
                CheckedTaskState.RepeatChecked,
              ].includes(changedTask.checked)
              ? 1
              : 0
            : 0;

          updateGoal({
            id: change.id,
            taskCount: change.taskCount,
            completedTaskCount: change.completedTaskCount,
            taskSortingMode: SortingMode.Custom,
            taskSorting: change.taskSorting,
            goalSortingMode: GoalSortingMode.Custom,
            goalSorting: change.goalSorting,
          });
          return;
        }

        updateGoal({
          id: change.id,
          taskSortingMode: SortingMode.Custom,
          taskSorting: change.taskSorting,
          goalSortingMode: GoalSortingMode.Custom,
          goalSorting: change.goalSorting,
        });
      });
    },
    [oldItems, updateGoal, updateTask],
  );
};
