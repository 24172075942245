export enum Options {
  PAGE = 'page',
  TOPBAR = 'top-bar',
  MENU = 'menu',
  SIDE_NAVIGATION = 'side-navigation',
  MOBILE_NAVIGATION = 'mobile-navigation',
  SIDE_NAVIGATION_BACKDROP = 'side-navigation-backdrop',
  DETAIL_COLUMN = 'detail-column',
  TOOLTIP = 'tooltip',
  PUSH_NOTIFICATION_MESSAGE = 'push-notification-message',
  IOS_BANNER = 'ios-banner',
}

const order = [
  Options.PAGE,
  Options.MENU,
  Options.TOPBAR,
  Options.SIDE_NAVIGATION_BACKDROP,
  Options.SIDE_NAVIGATION,
  Options.MOBILE_NAVIGATION,
  Options.DETAIL_COLUMN,
  Options.TOOLTIP,
  Options.PUSH_NOTIFICATION_MESSAGE,
  Options.IOS_BANNER,
];

export function index(option: Options): number {
  return order.findIndex((value) => value === option);
}
